<template>
  <div>
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner" align-v="end">
        <b-link class="brand-logo">
          <logo />
          <h2 class="brand-text text-primary ml-1 d-none d-md-block">
            {{ appName }}
          </h2>
        </b-link>
        <b-navbar-nav class="nav align-items-center ml-auto absolute-locale">
          <locale></locale>
        </b-navbar-nav>
      </b-row>
    </div>

    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Login v1 -->
        <b-card class="mb-0">
          <h3 class="brand-text text-primary ml-1 text-center">
            {{ $t("breadcrumb.login") }}
          </h3>

          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="validateForm">
              <n-input :fields="fields" v-model="data">
                <template #password="item">
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">
                        {{ $t(item.field.label) }}
                      </label>
                      <b-link :to="{ name: 'forgot-password' }">
                        <small>{{ $t("breadcrumb.forgotPassword") }}</small>
                      </b-link>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      :vid="item.field.key"
                      :name="item.field.label"
                      :rules="item.field.rules"
                    >
                      <n-password-input
                        v-model="data[item.field.key]"
                        :ref="item.field.key"
                        :id="item.field.key"
                        :name="item.field.key"
                        :placeholder="$t(item.field.label)"
                        :errors="errors"
                        :disabled="item.field.disabled"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      ></n-password-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
              </n-input>

              <n-button-loading
                type="submit"
                variant="primary"
                :loading="loading"
                :disabled="invalid"
                block
              >
                {{ $t("button.login") }}
              </n-button-loading>
            </b-form>
          </validation-observer>
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import Logo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BNavbarNav,
  BCard,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import { initialAbility } from "@/libs/acl/config";
import store from "@/store/index";
import NPasswordInput from "@/components/NPasswordInput";
import NInput from "@/components/NInput";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormInput from "./formInput";
import { $themeConfig } from "@themeConfig";
import NButtonLoading from "@/components/NButtonLoading";
import Locale from "@/layouts/components/app-navbar/LocaleDropdown.vue";
import { mapAbility } from "@/libs/helper";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BNavbarNav,
    BCard,
    Logo,
    Locale,
    NPasswordInput,
    NInput,
    NButtonLoading,
  },
  data() {
    return {
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      data: {
        email: "",
        password: "",
      },
      loading: false,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validateForm() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.submit();
        }
      });
    },
    submit() {
      this.loading = true;
      useJwt
        .login({
          ...this.data,
        })
        .then((response) => {
          const data = response.data.data;
          const userData = data.user;
          useJwt.setToken(data.accessToken);
          useJwt.setRefreshToken(data.refreshToken);
          localStorage.setItem("userData", JSON.stringify(userData));
          this.$store.commit("profile/UPDATE_PROFILE", userData);

          let newAbility = [...initialAbility];
          if (userData.ability) {
            newAbility = mapAbility(userData, newAbility, initialAbility);
          }
          this.$ability.update(newAbility);
          this.$router
            .replace(
              getHomeRouteForLoggedInUser(
                "admin",
                this.$route.query.redirect,
                newAbility
              )
            )
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("general.welcomeUser", {
                    name: this.data.firstName,
                  }),
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: this.$t("alert.successLoggedIn"),
                },
              });
            });
        })
        .catch((error) => {
          this.loading = false;
          if (error.response?.status === 422) {
            this.$refs.loginForm.setErrors(error.response.data.validate);
          }
        });
    },
  },
  setup() {
    const { appName } = $themeConfig.app;
    const fields = FormInput;
    return {
      appName,
      fields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
